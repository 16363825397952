export enum QuestionTypeEnum {
  SingleSelection = 0,
  Scale = 1,
  IconBox = 2,
  FreeText = 3,
  MidScreen = 4,
  DateInput = 5,
  TimeInput = 6,
  SingleLineInput = 7,
  Location = 8,
  EndPage = 9,
  FileUpload = 10,
  NumericInput = 11,
  WeightInput = 12,
  HeightInput = 13
}
